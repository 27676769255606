<template>
  <div
    class="container"
    id="workBillBoard"
    v-title
    :data-title="$t('i18n.workOrderManagement')"
  >
    <div id="outer-title">{{ $t("i18n.workOrderManagement") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @getEditData="getEditData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
    <el-dialog
      :title="$t('i18n.workOrderManagement')"
      v-model="dialogDetailVisible"
      center
      width="50%"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <div class="half-form">
        <el-form :model="form" label-width="100px">
          <el-form-item
            v-for="item in billLabel"
            :key="item.id"
            :label="$t('i18n.' + item.label) + '：'"
          >
            <span v-if="item.label == 'elevatorModel'">
              {{ setEleType(billDetail[item.value], 1) }}
            </span>
            <span v-else-if="item.label == 'productionDate'">
              {{ setEleType(billDetail[item.value], 2) }}
            </span>
            <span v-else>{{ billDetail[item.value] }}</span>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
      :title="$t('i18n.newWorkOrder')"
      v-model="dialogAddVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <jl-form :columns="addColumns" :option="btnOption"></jl-form>
    </el-dialog>
    <el-dialog
      :title="$t('i18n.orderExport')"
      v-model="exportVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <jl-form
        :columns="exportColumns"
        @onSubmit="exportBills"
        @resetForm="dialogOnEvent('exportVisible', false)"
      ></jl-form>
    </el-dialog>
    <el-dialog
      :title="$t('i18n.' + dialogTitle)"
      v-model="dialogResignVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <jl-form :columns="resignColumns" :option="resignOption"></jl-form>
    </el-dialog>
    <el-dialog
      :title="$t('i18n.inspectPage')"
      v-model="dialogFormVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <jl-form
        :columns="inspectColumns"
        @onSubmit="onSubmit"
        @resetForm="resetForm"
      ></jl-form>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import jlTable from "../../components/table";
import jlForm from "../../components/form";
export default {
  name: "WorkBillboard",
  components: { jlTable, jlForm },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();
    const state = reactive({
      dialogTitle: "reassignOrder",
      formInline: {
        page: 1,
        pageSize: 10,
      },
      billModelData: [],
      inspectStatusData: [],
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // proxy.$defined.btnPermission("新增工单"), // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "billNumber",
          label: "orderNumber",
          align: "center",
          search: true,
          type: "input",
          width: 180,
          formatter: (row) => {
            return `<a onclick="showDetail(event)" data-id="${row.id}">
              ${row.billNumber}</a>`;
          },
        },
        {
          prop: "billModel",
          label: "orderCategory",
          align: "center",
          search: true,
          type: "select",
          data: [],
          props: { label: "code", value: "value" },
          formatter: (row) => {
            let item = state.billModelData.filter((item) => {
              return item.value == row.billModel;
            });
            return item.length > 0 ? t("i18n." +item[0].code) : "";
          },
        },
        {
          prop: "alias",
          label: "projectAlias",
          align: "left",
          search: true,
          width: 240,
          type: "input",
        },
        {
          prop: "enumber",
          label: "elevatorNo",
          align: "center",
          search: true,
          type: "input",
        },
        {
          prop: "billstatus",
          label: "currentStatus",
          align: "center",
          search: true,
          type: "select",
          data: proxy.$config.billStatus,
          props: { label: "locale", value: "id" },
          formatter: (row) => {
            let current = proxy.$config.billStatus.filter((item) => {
              return item.id == row.billstatus;
            });
            if (current.length === 0) {
              return `<span>${row.billstatus}</span>`;
            } else {
              return `<a style="color: ${current[0].color}">
                ${t("i18n." + current[0].locale)}
              </a>`;
            }
          },
          method: (row) => {
            enterProcessPage(row.id);
          },
        },
        {
          prop: "actor",
          label: "handler",
          align: "center",
          search: true,
          type: "input",
        },
        {
          prop: "assists",
          label: "supportStaff",
          align: "center",
          search: false,
        },
        {
          prop: "startTime",
          label: "responseTime",
          align: "center",
          width: 160,
        },
        {
          prop: "startTime",
          label: "startTime",
          align: "center",
          search: true,
          rows: false,
          type: "date",
        },
        {
          prop: "endTime",
          label: "deadline",
          align: "center",
          search: true,
          rows: false,
          type: "date",
        },
        {
          prop: "usageType",
          label: "homeLift1",
          align: "center",
          value: false,
          rows: false,
          search: true,
          type: "checkbox",
        },
        {
          prop: "inspectStatus",
          label: "inspectResult",
          align: "center",
          search: false,
          formatter: (row) => {
            let current = state.inspectStatusData.filter((item) => {
              return item.value == row.inspectStatus;
            });
            if (current.length === 0) {
              return `<span>-</span>`;
            } else {
              if (row.inspectStatus == 30) {
                let color = row.inspectResult ? "Black" : "OrangeRed";
                return `<a style="color: ${color}">
                  ${t("i18n." + current[0].code)}
                </a>`;
              } else {
                return `${t("i18n." + current[0].code)}`;
              }
            }
          },
          method: (row) => {
            if (row.inspectStatus == 30) {
              editItem(row);
            }
          },
        },
        {
          label: "operation",
          align: "left",
          search: false,
          width: 180,
          formatter: (row) => {
            let current = proxy.$config.billStatus.filter((item) => {
              return item.id == row.billstatus;
            });
            let str = "";
            if (row.billstatus == 50) {
              str += `<a data-inspectStatus="${row.inspectStatus}"
                data-id="${row.id}" onclick="enterView(event)">
                <i class="el-icon-view"></i>&nbsp;${t("i18n.report")}</a>
                <a data-id="${row.id}" onclick="exportBillOne(event)">
                <i class="el-icon-download"></i>&nbsp;${t("i18n.export")}</a>`;
            } else {
              if (
                current[0].last === "1" &&
                proxy.$defined.btnPermission("派发工单")
              ) {
                str += `<a data-id="${row.id}"
                  onclick="handBillsResign(event, 'reassignOrder')">
                  <i class="el-icon-edit-outline"></i>&nbsp;
                  ${t("i18n.reassignOrder")}</a>`;
              }
              if (
                row.billstatus == 5 &&
                proxy.$defined.btnPermission("派发工单")
              ) {
                str += `<a data-id="${row.id}"
                  onclick="handBillsResign(event, 'sendOrder')">
                  <i class="el-icon-edit-outline"></i>&nbsp;
                  ${t("i18n.sendOrder")}</a>`;
              }
              if (
                proxy.$defined.btnPermission("删除工单") &&
                (row.billstatus == 5 ||
                  row.billstatus == 10 ||
                  row.billstatus == 40 ||
                  row.billstatus == 45)
              ) {
                str += `<a data-id="${row.id}" onclick="deleteWorkBill(event)">
                  <i class="el-icon-delete"></i>&nbsp;
                    ${t("i18n.delete")}
                  </a>`;
              }
            }
            return str;
          },
        },
      ],
      operates: {
        // 列操作按钮
        width: 200,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "new",
            class: "add",
            icon: "el-icon-plus",
            show: proxy.$defined.btnPermission("派发工单"),
            type: "outer",
            method: () => {
              dialogOnEvent("dialogAddVisible", true);
            },
          },
          {
            id: "2",
            label: "export",
            class: "export",
            icon: "el-icon-upload2",
            show: true,
            type: "outer",
            method: () => {
              dialogOnEvent("exportVisible", true);
            },
          },
        ],
      },
      total: 0,
      loading: true,
      editData: {},
      dialogDetailVisible: false,
      billDetail: {},
      billLabel: [
        { label: "orderNumber", value: "billnumber" },
        { label: "maintenanceType", value: "billModelName" },
        { label: "buildingUsingParty", value: "userCompany" },
        { label: "address", value: "address" },
        { label: "contractInformation", value: "phone" },
        { label: "contactPerson", value: "contact" },
        { label: "mobilePhone", value: "mobile" },
        { label: "elevatorModel", value: "elevatortype" },
        { label: "controlMode", value: "controllerType" },
        { label: "elevatorNo", value: "elevatornumber" },
        { label: "maintenanceContractNo", value: "upkeepcontractnumber" },
        { label: "maintenanceName", value: "maintainname" },
        { label: "productionDate", value: "productiontime" },
        { label: "ratedLoad", value: "ratedweight" },
        { label: "ratedSpeed", value: "ratedspeed" },
        { label: "address", value: "useaddress" },
        { label: "contractNature", value: "maintainProperty" },
        { label: "startTime", value: "starttime" },
        { label: "result", value: "result" },
        { label: "maintenancePerson", value: "maintenancePersonal" },
      ],
      dialogAddVisible: false,
      exportVisible: false,
      addColumns: [
        {
          prop: "billModel",
          label: "orderType",
          type: "select",
          data: [],
          uploadUrl: "billModel",
          props: { label: "code", value: "value" },
          rules: [{ required: true, message: t("i18n.select") }],
          change: (val) => {
            setOrderType(val);
          },
        },
        {
          prop: "enumber",
          label: "elevatorNo",
          type: "remote",
          props: { label: "number", value: null },
          rules: [{ required: true, message: t("i18n.input") }],
          loading: false,
          data: [],
          change: (val) => {
            getAllPersons(val, "addColumns", "maintenancePersonnelId");
          },
          remoteMethod: (val) => {
            remoteEleMethod(val, "addColumns", "enumber");
          },
        },
        {
          prop: "maintenancePersonnelId",
          label: "maintenancePerson",
          type: "select",
          data: [],
          props: { label: "name", value: "id" },
          rules: [{ required: true, message: t("i18n.select") }],
        },
        {
          prop: "faultPerson",
          label: "serviceStaff",
          type: "input",
          show: false,
        },
        {
          prop: "faultPersonTelephone",
          label: "contractInformation",
          type: "input",
          show: false,
        },
        {
          prop: "faultDescription",
          label: "faultDescription",
          type: "input",
          show: false,
        },
        {
          prop: "contentDescription",
          label: "description",
          type: "input",
          show: false,
        },
        {
          prop: "projectName",
          label: "projectName",
          type: "input",
          show: false,
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "eleType",
          label: "elevatorType",
          type: "select",
          props: { label: "code", value: "value" },
          data: [],
          uploadUrl: "elevator_type",
          show: false,
          rules: [{ required: true, message: t("i18n.select") }],
        },
        {
          prop: "eleNumber",
          label: "numberOfElevator",
          type: "input",
          show: false,
          rules: [{ required: true, message: t("i18n.input") }],
        },
      ],
      btnOption: [
        {
          label: "sendLater",
          class: "primary",
          show: proxy.$defined.btnPermission("派发工单"),
          method: (row, validate) => {
            submitForm(row, validate, 2);
          },
        },
        {
          label: "sendImmediately",
          class: "reset",
          show: proxy.$defined.btnPermission("派发工单"),
          method: (row, validate) => {
            submitForm(row, validate, 1);
          },
        },
        {
          label: "cancel",
          class: "cancel",
          show: true,
          method: () => {
            dialogOnEvent("dialogAddVisible", false);
          },
        },
      ],
      exportColumns: [
        {
          prop: "startTime1",
          label: "startTime",
          type: "date",
        },
        {
          prop: "startTime2",
          label: "deadline",
          type: "date",
        },
        {
          prop: "enumber",
          label: "elevatorNo",
          type: "remote",
          props: { label: "number", value: null },
          loading: false,
          data: [],
          remoteMethod: (val) => {
            remoteEleMethod(val, "exportColumns", "enumber");
          },
        },
        {
          prop: "billCategory",
          label: "orderType",
          type: "select",
          data: [],
          props: { label: "code", value: "value" },
        },
        {
          prop: "billStatus",
          label: "status",
          type: "select",
          data: [],
          props: { label: "code", value: "value" },
          uploadUrl: "bill_output_status",
        },
      ],
      dialogResignVisible: false,
      resignColumns: [
        {
          prop: "billNumber",
          label: "orderNumber",
          readonly: true,
        },
        {
          prop: "billModel",
          label: "orderType",
          readonly: true,
        },
        {
          prop: "enumber",
          label: "elevatorNo",
          type: "remote",
          props: { label: "number", value: null },
          rules: [{ required: true, message: t("i18n.input") }],
          loading: false,
          data: [],
          change: (val) => {
            getAllPersons(val, "resignColumns", "maintenancePersonnelId");
          },
          remoteMethod: (val) => {
            remoteEleMethod(val, "resignColumns", "enumber");
          },
        },
        {
          prop: "maintenancePersonnelId",
          label: "maintenancePerson",
          type: "select",
          data: [],
          props: { label: "name", value: "id" },
          rules: [{ required: true, message: t("i18n.select") }],
        },
      ],
      resignOption: [
        {
          label: "reassignOrder",
          class: "primary",
          show: proxy.$defined.btnPermission("派发工单"),
          method: (row, validate) => {
            let flag = state.dialogTitle === "reassignOrder" ? 3 : 4;
            submitForm(row, validate, flag);
          },
        },
        {
          label: "cancel",
          class: "cancel",
          show: true,
          method: () => {
            dialogOnEvent("dialogResignVisible", false);
          },
        },
      ],
      dialogFormVisible: false,
      inspectColumns: [
        {
          prop: "inspectResult",
          label: "inspectResult",
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
      ],
      editId: "",
    });
    const init = async (p) => {
      state.loading = true;
      state.formInline = JSON.parse(JSON.stringify(p));
      if (p.startTime instanceof Date) {
        p.startTime = proxy.$defined.format(p.startTime) + " 00:00:00";
      }
      if (p.endTime instanceof Date) {
        p.endTime = proxy.$defined.format(p.endTime) + " 00:00:00";
      }
      const { data } = await proxy.$api.maintenance.handbillPages(setData(p));
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const getBillModelData = () => {
      proxy.$api.common.getLineageByCode("billModel").then((res) => {
        state.billModelData = res.data.lineages;
        initData();
        detailOnNew();
      });
    };
    getBillModelData();

    const setData = (data) => {
      let params = JSON.parse(JSON.stringify(data));
      delete params.pageNumber;
      let arr = ["page", "pageSize", "sortOrder", "sortName", "usageType"];
      for (var key in params) {
        if (arr.indexOf(key) == -1 && key.indexOf("search_") == -1) {
          params["search_" + key + "_LIKE"] = params[key];
          delete params[key];
        }
      }
      params.sortOrder = "DESC";
      params.sortName = "wb.created_date";
      params.usageType = params.usageType ? "1" : "";
      return params;
    };

    const getInspectStatus = () => {
      proxy.$api.common.getLineageByCode("inspectStatus").then((res) => {
        state.inspectStatusData = res.data.lineages;
      });
    };
    getInspectStatus();

    const editItem = (row) => {
      state.dialogFormVisible = true;
      state.inspectColumns.map((item) => {
        item.isUpdate = true;
        item.readonly = row.inspectResult ? true : false;
        item.value = row.inspectResult || "";
      });
      state.editId = row.id;
    };

    const getLineages = async () => {
      let { data } = await proxy.$api.common.getLineageByCode("elevator_variety");
      state.lineages = data.lineages;
    };

    const setEleType = (val, u) => {
      if (!val) {
        return "";
      }
      if (u === 1) {
        let current = state.lineages.filter((item) => {
          return item.value == val;
        });
        return t("i18n." + current[0].code);
      } else {
        return Array.isArray(val) ? val.join("-") : "";
      }
    };

    const enterProcessPage = (id) => {
      // 进入工单过程页面
      let routeData = router.resolve({
        path: "/maintenance/workBillwbStatus",
        query: { id: id },
      });
      window.open(routeData.href, "_blank");
    };

    window.deleteWorkBill = (e) => {
      // 删除
      const id = e.target.dataset.id;
      let callback = () => {
        proxy.$api.maintenance.deleteHandBills(id).then(() => {
          init(state.formInline);
          proxy.$defined.tip(t("i18n.delete") + t("i18n.success"), "success");
        });
      };
      proxy.$defined.confirm(
        callback,
        t("i18n.doYouWantToDelete"),
        t("i18n.delete")
      );
    };

    window.handBillsResign = async (e, title) => {
      // 工单改派
      state.dialogTitle = title;
      state.resignOption[0].label = title;
      let id = (state.editId = e.target.dataset.id);
      let { data } = await proxy.$api.maintenance.getBillDetail(id);
      remoteEleMethod(data.elevatornumber, "resignColumns", "enumber");
      getAllPersons(
        data.elevatornumber,
        "resignColumns",
        "maintenancePersonnelId"
      );
      state.resignColumns.map((item) => {
        if (item.prop === "billModel") {
          item.readonly = true;
          item.isUpdate = true;
          item.value = data.billModelName;
        } else if (item.prop === "billNumber") {
          item.isUpdate = true;
          item.value = data.billnumber;
        } else if (item.prop === "enumber") {
          item.isUpdate = true;
          item.value = data.elevatornumber;
          item.readonly = title === "sendOrder";
        } else if (item.prop === "maintenancePersonnelId") {
          item.isUpdate = true;
          item.value = data.maintenancePersonnelId;
          item.readonly = title === "sendOrder";
        }
        console.log("item:",item)
      });
      
      state.dialogResignVisible = true;
    };

    window.enterView = (e) => {
      // 工单查看页面
      const id = e.target.dataset.id;
      const inspectStatus = e.target.dataset.inspectstatus;
      let routeData = router.resolve({
        path: "/maintenance/generateWorkBillView",
        query: { id: id, inspectStatus: inspectStatus },
      });
      window.open(routeData.href, "_blank");
    };

    window.exportBillOne = async (e) => {
      // 单个工单导出
      const id = e.target.dataset.id;
      let res = await proxy.$api.maintenance.exportBillOne(id);
      proxy.$defined.exportFunc(res);
    };

    window.showDetail = async (e) => {
      const id = e.target.dataset.id;
      let { data } = await proxy.$api.maintenance.getBillDetail(id);
      state.billDetail = data;
      state.dialogDetailVisible = true;
    };

    const getEditData = (row) => {
      state.editData = row;
    };

    const exportBills = (val) => {
      // 导出工单
      if (paramsValidate(val)) {
        for (let key in val) {
          if (val[key]) {
            if (key === "enumber") {
              val.enumber = val.enumber.number;
            } else if (key === "startTime1" || key === "startTime2") {
              val[key] = proxy.$defined.format(val[key]);
            }
          } else {
            val[key] = "";
          }
        }
        proxy.$defined.exportJsFunc(
          proxy.$api.maintenance.exportWorkBills(val)
        );
        state.exportVisible = false;
      } else {
        proxy.$defined.tip(t("i18n.emptySearch"));
      }
    };

    const paramsValidate = (params) => {
      let flag = false;
      for (var key in params) {
        if (params[key]) {
          return true;
        }
      }
      return flag;
    };

    const getAllPersons = async (val, column, key) => {
      // 电梯工号 change
      let obj = { elevatorId: val.id, name: "" };
      let { data } = await proxy.$api.maintenance.getBillPerson(obj);
      var index = state[column].findIndex((item) => {
        return item.prop === key;
      });
      state[column][index].data = data;
    };

    const setOrderType = (id) => {
      // 工单类型 change
      var bool = [];
      if (parseInt(id) < 40 || id === "80") {
        bool = [true, true, true, "", "", "", "", "", "", ""];
      } else if (parseInt(id) < 60) {
        bool = [true, true, true, true, true, true, "", "", "", ""];
      } else if (id === "60") {
        bool = [true, true, true, "", "", "", true, "", "", ""];
      } else if (id === "70") {
        bool = [true, "", true, "", "", "", "", true, true, true];
      }
      state.addColumns.map((item, index) => {
        state.addColumns[index].show = bool[index] === true;
      });
    };

    const remoteEleMethod = (val, column, key) => {
      var index = state[column].findIndex((item) => {
        return item.prop === key;
      });
      if (val) {
        state[column][index].loading = true;
        setTimeout(() => {
          proxy.$api.maintenance
            .getAllEle({ elevatorNumber: val })
            .then((data) => {
              state[column][index].data = data.data;
              state[column][index].loading = false;
            });
        }, 10);
      } else {
        state[column][index].data = [];
      }
    };

    const dialogOnEvent = (dialog, bool) => {
      state[dialog] = bool;
    };

    const submitForm = (form, validate, actionType) => {
      // 派发工单
      validate
        .then(() => {
          submitOnEvent(form, actionType);
        })
        .catch(() => {});
    };

    const submitOnEvent = async (data, actionType) => {
      let form = JSON.parse(JSON.stringify(data));
      let obj = {};
      obj.elevatorId = form.elevatorId = form.enumber.id;
      obj.enumber = form.enumber = form.enumber.number;
      if (actionType == 3) {
        // 工单改派
        obj.maintenancePersonnelId = form.maintenancePersonnelId;
        obj.actionType = 1;
        await proxy.$api.maintenance.handBillsResign(state.editId, obj);
        proxy.$defined.tip(
          t("i18n.reassignOrder") + t("i18n.success"),
          "success"
        );
        state.dialogResignVisible = false;
      } else if (actionType == 4) {
        // 已创建工单，派发工单
        await proxy.$api.maintenance.sendBill(state.editId);
        proxy.$defined.tip(t("i18n.sendOrder") + t("i18n.success"), "success");
        state.dialogResignVisible = false;
      } else {
        if (route.query.enumber) {
          form.elevatorId = route.query.id;
          form.enumber = route.query.enumber;
          form.billModel = route.query.billModel || form.billModel;
        }
        form.actionType = actionType;
        await proxy.$api.maintenance.addBillOne(form);
        proxy.$defined.tip(t("i18n.new") + t("i18n.success"), "success");
        state.dialogAddVisible = false;
      }
      init(state.formInline);
    };

    const initData = () => {
      var index = state.columns.findIndex((item) => {
        return item.prop === "billModel";
      });
      var index1 = state.exportColumns.findIndex((item) => {
        return item.prop === "billCategory";
      });
      let data = JSON.parse(JSON.stringify(state.billModelData));
      let obj = { value: "20", code: "maintenanceOrder" };
      data.splice(0, 4, obj);
      state.exportColumns[index1].data = state.columns[index].data = data;
      getLineages();
    };

    const detailOnNew = () => {
      if (route.query.enumber && route.query.billModel !== undefined) {
        state.dialogAddVisible = true;
        state.addColumns.map((item) => {
          if (item.prop === "enumber") {
            item.readonly = true;
            item.isUpdate = true;
            item.value = route.query.enumber;
            getAllPersons(
              route.query.id,
              "addColumns",
              "maintenancePersonnelId"
            );
          }
        });
        if (route.query.billModel === "") {
          return;
        }
        setOrderType("50");
        state.addColumns.map((item) => {
          if (item.prop === "billModel") {
            item.readonly = true;
            let locale = state.billModelData.filter((item) => {
              return item.value == route.query.billModel;
            })[0].code;
            item.isUpdate = true;
            item.value = t("i18n." + locale);
          }
        });
      }
    };

    const onSubmit = async (row) => {
      await proxy.$api.maintenance.setInspectResult(state.editId, {
        inspectResult: row.inspectResult,
      });
      resetForm();
      init(state.formInline);
    };

    const resetForm = () => {
      state.dialogFormVisible = false;
    };

    return {
      ...toRefs(state),
      init,
      remoteEleMethod,
      getEditData,
      setEleType,
      enterProcessPage,
      getAllPersons,
      setOrderType,
      submitForm,
      dialogOnEvent,
      exportBills,
      editItem,
      onSubmit,
      resetForm,
    };
  },
};
</script>
<style lang="scss">
#workBillBoard {
  .half-form {
    .el-form-item {
      margin-bottom: 0;
      width: 45%;
      margin-left: 0;
      vertical-align: top;
    }
  }
  .checkbox {
    @include form-btn($btn_transparent_bg);
    color: $font_color;
    border-radius: 20px;
  }
  .checkbox.is-checked {
    background: #409efe;
    border: none;
    .el-checkbox__label {
      color: #fff;
    }
  }
}
@media screen and (max-width: 768px) {
  #workBillBoard {
    .half-form {
      .el-form-item {
        width: 100%;
      }
    }
  }
}
</style>
